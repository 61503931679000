import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide } from "@material-ui/core";
import { date } from "yup";





const BlogContent = [ 
  {
    img: "blog23.jpg",
    date: "Aug 26, 2024",
    title: `Organizing Virtual Events and Meetups in Coworking Spaces`,
    desc: `Virtual coworking spaces have revolutionized the way we work, breaking down geographical barriers and fostering a sense of community among remote workers. One of the key elements`,
    routePath: "/organizing-virtual-events-and-meetups-in-coworking-spaces",
  }, 
  {
    img: "blog22.jpg",
    date: "Aug 19, 2024",
    title: `The Role of Virtual Coworking Offices in Remote Work Trends`,
    desc: `The landscape of work has been undergoing a dramatic transformation, especially in the wake of the COVID-19 pandemic. As organizations and individuals adapt to the new normal, virtual coworking offices`,
    routePath: "/the-role-of-virtual-coworking-offices-in-remote-work-trends",
  },
  {
    img: "blog21.jpg",
    date: "Aug 12, 2024",
    title: ` Tools and Technologies that Power Virtual Coworking Offices`,
    desc: `In today's rapidly evolving work environment, virtual coworking offices have emerged as a popular alternative to traditional office spaces. They offer flexibility, cost savings`,
    routePath: "/tools-and-technologies-that-power-virtual-coworking-offices",
  }
  
];




const BlogStyle3 = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <>
      {BlogContent.map((item, i) => (
        <div className="post-meta" key={i}>
          <div className="imgsetup">
            <img
              src={`images/assets/${item.img}`}
              alt="media"
              className="image-meta1"
            />
          </div>
          <div className="post">
            <div className="date">{item.date}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <p>{item.desc}</p>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center">
              <span>Learn More</span>
              {/* <i className="flaticon-right-arrow"></i> */}
            </Link>
          </div>
          {/* End post */}

        </div>
        // /.post-meta
      ))}
    </>
  );
};

export default BlogStyle3;
